import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Confirmation from './Confirmation';
import './Welcome.css';

const Welcome = ({ email }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false); // To toggle the Confirmation page

  const handleSend = async (e) => {
    e.preventDefault();

    let valid = true;

    if (!subject) {
      setSubjectError('Please complete all required fields.');
      valid = false;
    } else {
      setSubjectError('');
    }

    if (!message) {
      setMessageError('Please complete all required fields.');
      valid = false;
    } else if (wordCount < 20) {
      setMessageError('Please write at least 20 words before sending.');
      valid = false;
    } else {
      setMessageError('');
    }

    if (valid) {
      try {
        const token = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_SITE_KEY,
          { action: 'submit' }
        );
        setCaptchaToken(token);

        await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          {
            from_name: 'Jamie Hooper',
            to_name: 'Admin',
            email,
            subject,
            message,
            'g-recaptcha-response': token,
          },
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        );

        console.log('Email sent successfully');
        setShowConfirmation(true); // Transition to Confirmation.js
      } catch (error) {
        console.error('Error during submission:', error);
        alert('Failed to send message. Please try again.');
      }
    }
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessage(value);
    setWordCount(value.split(' ').filter((word) => word.trim().length > 0).length);
  };

  return (
    <>
      {!showConfirmation ? (
        <div className="container">
          <div className="left-section">
            <div className="logo-container">
              <img src="/logo.png" alt="Logo" className="logo" />
            </div>
            <div className="text-container">
              <div className="sign-in-text">Welcome</div>
              <div className="dropdown-container">
                <div className="dropdown-content">
                  <img
                    src="/profile-icon.png"
                    alt="Profile Icon"
                    className="profile-icon"
                  />
                  <span className="email">{email}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right-section">
            <form id="contact-form" onSubmit={handleSend}>
              <div className="input-group">
                <div className={`input-container ${subjectError ? 'error' : ''}`}>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    className="input"
                    placeholder=" "
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                  <label htmlFor="subject" className="input-label">
                    Subject
                  </label>
                  {subjectError && (
                    <div className="error-message subject-error">{subjectError}</div>
                  )}
                </div>
                <div className={`input-container message ${messageError ? 'error' : ''}`}>
                  <textarea
                    name="message"
                    id="message"
                    className="input"
                    placeholder=" "
                    value={message}
                    onChange={handleMessageChange}
                    required
                  />
                  <label htmlFor="message" className="input-label">
                    Message
                  </label>
                  {messageError && (
                    <div className="error-message message-error">{messageError}</div>
                  )}
                  <div className="word-count">{wordCount} words</div>
                </div>
              </div>
              <button className="next-button" type="submit">
                Send
              </button>
            </form>
          </div>
        </div>
      ) : (
        <Confirmation />
      )}
    </>
  );
};

export default Welcome;
